export const userAuthProvider = {
    login: ({ email, password }) => {
        // console.log(email);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Credentials', true);
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Headers', '*');
        headers.append('Access-Control-Allow-Methods', '*');
        headers.append('GET', 'POST', 'OPTIONS');

        const request = new Request('https://weather-pwa.ru/auth/', {
            method: 'POST',
            // mode: 'no-cors',
            body: JSON.stringify({ email, password }),
            headers: headers,
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                // console.log(auth);
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Network error');
            });

        // if (username !== 'john' || password !== '123') {
        //     return Promise.reject();
        // }
        // localStorage.setItem('username', username);
        // return Promise.resolve();
    },
    register: ({ email, password, deviceinfo, uid}, handle) => {
        // console.log(email);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Credentials', true);
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Headers', '*');
        headers.append('Access-Control-Allow-Methods', '*');
        headers.append('GET', 'POST', 'OPTIONS');

        const request = new Request('https://weather-pwa.ru/auth/', {
            method: 'POST',
            // mode: 'no-cors',
            body: JSON.stringify({ email, password, deviceinfo, uid, handle }),
            headers: headers,
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                // console.log(auth);
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Network error');
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'John Doe',
        }),
    getPermissions: () => Promise.resolve(''),
};

// export default userAuthProvider;