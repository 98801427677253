import moment from "moment";
import SVG from 'react-inlinesvg';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

export const ChartSeries = ({weatherData, limit}) => {

  const { t } = useTranslation();

  const tempArray = [];
  const rainArray = [];
  const hoursArray = [];
  const iconsArray = [];
  const windArray = [];
  const cloudArray = [];
  const rainDropsArray = [];
  const windDirectionArray = [];
  const humidityArray = [];

  const graphColor1 = ['#2563eb', '#f43f5e'];
  const graphColor2 = ['#4ade80','#9333ea'];
  const graphColor3 = ['#ec4899','#38bdf8'];

  const fontSize = (limit != 6) ? '10px' : '11px';

  weatherData.metcheckData.forecastLocation.forecast.slice(0, limit).map((e, index) => {
      tempArray.push(e.temperature);
      rainArray.push(e.chanceofrain);
      hoursArray.push(e.utcTime);
      iconsArray.push(e.icon);
      windArray.push(Math.floor(e.windspeed*1.609));
      cloudArray.push(e.totalcloud);
      rainDropsArray.push(e.rain);
      windDirectionArray.push(e.winddirection);
      humidityArray.push(e.humidity);
  });

  const chartData = {
      series: [
        {
          name: '%',
          fullname: t('clouds'),
          data: cloudArray,
        },
        {
          name: '%',
          fullname: t('rain'),
          data: rainArray,
        },
      ],
      options: {
        colors: graphColor1,
        chart: {
          type: 'area',
          height: 'auto',
          parentHeightOffset: 10,
          // offsetX: -10,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },

        fill: {
          opacity: 0.9,
          colors: graphColor1,
          type: 'gradient',
          gradient: {
              shade: 'dark',
              shadeIntensity: 1,
              opacityFrom: 0.8,
              opacityTo: 0.01,
              stops: [0, 80, 100]
            }
        },
  
        dataLabels: {
          enabled: true,
          // formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          //     return value + w.config.series[seriesIndex].name;
          //   },
          textAnchor: 'middle',
          offsetY: -5,
          style: {
            fontSize: fontSize,
            colors: ['#dbe9fe', '#dbe9fe'],
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'butt',
          colors: ['#c2d5ff', '#ffd7de'],
          width: 2,
        },
        legend: {
          show: true,
          position: 'bottom',
          formatter: function(seriesName, opts) {
            return [opts.w.config.series[opts.seriesIndex].fullname, " ", seriesName];
          },
          onItemClick: {
            toggleDataSeries: false
          },
          labels: {
            colors: '#ccc',
          },
        },
        markers: {
          colors: graphColor1,
        },
        grid: {
          show: false,
        },
        tooltip: {
          // fillSeriesColor: true,
          theme: 'dark',
          x: {
            show: false,
            format: 'dd MMM HH:mm',
          },
          y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return w.config.series[seriesIndex].fullname + ' ' + value + w.config.series[seriesIndex].name;
              },
              title: {
                  formatter: (seriesName) => {},
              },
          },
          fixed: {
            enabled: false,
            position: 'topLeft',
          },
        },
        xaxis: {
          type: 'datetime',
          decimalsInFloat: 0,
          categories: hoursArray,
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          labels: {
              show: false,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
        yaxis: [{
          show: true,
          max: 100,
          // max: (tempArray) => {
          //   return tempArray + 5;
          // },
          // min: (tempArray) => {
          //   return tempArray - 2;
          // },
          labels: {
            show: false,
          },
        },
        {
          opposite: true,
          max: 100,
          min: 0,
          labels: {
              show: false,
          },
        },
      ],
      },
  };

  const chartData2 = {
    series: [
      {
        name: t('km_h'),
        fullname: t('wind_speed'),
        data: windArray,
      },
      {
        name: '°C',
        fullname: t('temperature'),
        data: tempArray,
      },
    ],
    options: {
      colors: graphColor2,
      chart: {
        type: 'area',
        height: 'auto',
        parentHeightOffset: 10,
        // offsetX: -10,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },

      fill: {
        opacity: 0.9,
        colors: graphColor2,
        type: 'gradient',
        gradient: {
            shade: 'dark',
            shadeIntensity: 1,
            opacityFrom: 0.8,
            opacityTo: 0.01,
            stops: [0, 80, 100]
          }
      },

      dataLabels: {
        enabled: true,
        // formatter: function(value, { seriesIndex, dataPointIndex, w }) {
        //     return value + w.config.series[seriesIndex].name;
        //   },
        textAnchor: 'middle',
        offsetY: -5,
        style: {
          fontSize: fontSize,
          colors: ['#dbe9fe', '#dbe9fe'],
        },
        background: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        colors: ['#c8ffdc', '#e3c5ff'],
        width: 2,
      },
      legend: {
        show: true,
        position: 'bottom',
        formatter: function(seriesName, opts) {
          return [opts.w.config.series[opts.seriesIndex].fullname, " ", seriesName];
        },
        onItemClick: {
          toggleDataSeries: false
        },
        labels: {
          colors: '#ccc',
        },
      },
      markers: {
        colors: graphColor2,
      },
      grid: {
        show: false,
      },
      tooltip: {
        // fillSeriesColor: true,
        theme: 'dark',
        x: {
          show: false,
          format: 'dd MMM HH:mm',
        },
        y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return w.config.series[seriesIndex].fullname + ' ' + value + w.config.series[seriesIndex].name;
            },
            title: {
                formatter: (seriesName) => {},
            },
        },
        fixed: {
          enabled: false,
          position: 'topLeft',
        },
      },
      xaxis: {
        type: 'datetime',
        decimalsInFloat: 0,
        categories: hoursArray,
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
      },
      yaxis: [{
        show: true,
        max: 35,
        // max: (tempArray) => {
        //   return tempArray + 4;
        // },
        // min: (tempArray) => {
        //   return tempArray - 2;
        // },
        labels: {
          show: false,
        },
      },
      {
        opposite: true,
        // max: 40,
        // min: 0,
        max: (tempArray) => {
          return tempArray + 4;
        },
        min: (tempArray) => {
          return tempArray - 2;
        },
        labels: {
            show: false,
        },
      },
    ],
    },
};

const chartData3 = {
  series: [
    {
      name: t('mm_h'),
      fullname: t('rain'),
      data: rainDropsArray,
    },
    {
      name: '%',
      fullname: t('humidity'),
      data: humidityArray,
    },
  ],
  options: {
    colors: graphColor3,
    chart: {
      type: 'area',
      height: 'auto',
      parentHeightOffset: 10,
      // offsetX: -10,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    fill: {
      opacity: 0.9,
      colors: graphColor3,
      type: 'gradient',
      gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          opacityFrom: 0.8,
          opacityTo: 0.01,
          stops: [0, 80, 100]
        }
    },

    dataLabels: {
      enabled: true,
      // formatter: function(value, { seriesIndex, dataPointIndex, w }) {
      //     return value + w.config.series[seriesIndex].name;
      //   },
      textAnchor: 'middle',
      offsetY: -5,
      style: {
        fontSize: fontSize,
        colors: ['#dbe9fe', '#dbe9fe'],
      },
      background: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#ffd7de', '#c2d5ff'],
      width: 2,
    },
    legend: {
      show: true,
      formatter: function(seriesName, opts) {
        return [opts.w.config.series[opts.seriesIndex].fullname, " ", seriesName];
      },
      onItemClick: {
        toggleDataSeries: false
      },
      labels: {
        colors: '#ccc',
      },
    },
    markers: {
      colors: graphColor3,
    },
    grid: {
      show: false,
    },
    tooltip: {
      // fillSeriesColor: true,
      theme: 'dark',
      x: {
        show: false,
        format: 'dd MMM HH:mm',
      },
      y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].fullname + ' ' + value + w.config.series[seriesIndex].name;
          },
          title: {
              formatter: (seriesName) => {},
          },
      },
      fixed: {
        enabled: false,
        position: 'topLeft',
      },
    },
    xaxis: {
      type: 'datetime',
      decimalsInFloat: 0,
      categories: hoursArray,
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
          show: false,
      },
      axisBorder: {
          show: false,
      },
      axisTicks: {
          show: false,
      },
    },
    yaxis: [{
      show: true,
      max: 10,
      // max: (tempArray) => {
      //   return tempArray + 5;
      // },
      // min: (tempArray) => {
      //   return tempArray - 5;
      // },
      labels: {
        show: false,
      },
    },
    {
      opposite: true,
      max: 100,
      min: 0,
      labels: {
          show: false,
      },
    },
  ],
  },
};

const chartDirection = {
  series: [
    {
      name: t('km_h'),
      fullname: t('wind_speed'),
      data: windDirectionArray,
    },

  ],
  options: {
    colors: graphColor2,
    chart: {
      type: 'area',
      height: 'auto',
      parentHeightOffset: 10,
      // offsetX: -10,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      radar: {
        polygons: {
          strokeColor: '#e8e8e8',
          fill: {
              colors: ['#f8f8f8', '#fff']
          }
        }
      }
    },

    fill: {
      opacity: 0.9,
      colors: graphColor2,
      type: 'gradient',
      gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          opacityFrom: 0.8,
          opacityTo: 0.01,
          stops: [0, 80, 100]
        }
    },

    dataLabels: {
      enabled: true,
      // formatter: function(value, { seriesIndex, dataPointIndex, w }) {
      //     return value + w.config.series[seriesIndex].name;
      //   },
      textAnchor: 'middle',
      offsetY: -5,
      style: {
        fontSize: fontSize,
        colors: ['#dbe9fe', '#dbe9fe'],
      },
      background: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
      colors: ['#c8ffdc', '#e3c5ff'],
      width: 2,
    },
    legend: {
      show: true,
      formatter: function(seriesName, opts) {
        return [opts.w.config.series[opts.seriesIndex].fullname, " ", seriesName];
      },
      onItemClick: {
        toggleDataSeries: false
      },
      labels: {
        colors: '#ccc',
      },
    },
    markers: {
      colors: graphColor2,
    },
    grid: {
      show: false,
    },
    tooltip: {
      // fillSeriesColor: true,
      theme: 'dark',
      x: {
        show: false,
        format: 'dd MMM HH:mm',
      },
      y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].fullname + ' ' + value + w.config.series[seriesIndex].name;
          },
          title: {
              formatter: (seriesName) => {},
          },
      },
      fixed: {
        enabled: false,
        position: 'topLeft',
      },
    },
    xaxis: {
      type: 'datetime',
      decimalsInFloat: 0,
      categories: hoursArray,
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
          show: false,
      },
      axisBorder: {
          show: false,
      },
      axisTicks: {
          show: false,
      },
    },
    yaxis: [{
      show: true,
      max: 360,
      labels: {
        show: false,
      },
    },

  ],
  },
};

  return (
      <div className='ChartWeather'>

        {limit != 12 && (
          <div className="flex flex-row justify-around items-stretch text-xs text-white text-center pb-2 -ml-1 -mr-1">
            {iconsArray.map((e, index) => (
                <div key={index} className="flex flex-col flex-1">
                    <div className="text-gray-400">
                        {moment(moment(hoursArray[index]).valueOf() + moment.parseZone().utcOffset()*60000).hour()}:00
                    </div>
                    <div className={ e + "-icon flex relative items-center content-center text-center justify-center"}><SVG
                    src={"./images/weather/" + e + ".svg"} width="55" height="55" 
                    className="time-icon"
                    />
                    </div>

                </div>
            ))}
          </div>
        )}

        {limit === 12 && (
          <div className="flex flex-row justify-around text-xs text-white text-left py-2">
            {windArray.map((e, index) => (
                <div key={index}>
                    <div className="text-gray-400 -rotate-90">
                        {moment(moment(hoursArray[index]).valueOf() + moment.parseZone().utcOffset()*60000).hour()}:00
                    </div>
                </div>
            ))}
          </div>
        )}

        <div className="-ml-1 -mr-5 -mb-0">
          <Chart options={chartData2.options} series={chartData2.series} type='area' height={260} />
        </div>

        <div className="-ml-1 -mr-5">
          <Chart options={chartData.options} series={chartData.series} type='area' height={260} />
        </div>

        <div className="-ml-1 -mr-5">
          <Chart options={chartData3.options} series={chartData3.series} type='area' height={260} />
        </div>

        {/* <div className="-ml-1 -mr-5">
          <Chart options={chartDirection.options} series={chartDirection.series} type='radar' height={350} />
        </div> */}
      </div>
  );
  
};

export const weatherChart = (weatherData, limit = 6) => {

  return (
    <ChartSeries weatherData={weatherData} limit={limit} />
  );
}