import React, {useState} from "react";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import "leaflet/dist/leaflet.css";

import marker from '../assets/images/drone.svg';

import L, { Icon } from 'leaflet';

const myIcon = new Icon({
 iconUrl: marker,
 iconSize: [32,32]
});
        
const NewMap = ({lat, lon, childToParent}) => {

    const { t } = useTranslation();
    const [classes, setClasses] = useState('hidden');
    const [buttonclasses, setButonClasses] = useState('rounded-xl');
    const [showmap, setShowmap] = useState(t('show_map'));

    

    // const [position, setPosition] = useState({lat, lon});
            // console.log('map',lat, lon)

    const SetViewOnClick = ({ coords }) => {
        const map = useMap();
        map.setView(coords, 12);
        map._onResize();
        // map.invalidateSize();
        // console.log('coords',lat, lon);
        return null;
   
    };

    const AddMarker = ({ saveMarkers }) => {
        let map = useMapEvents({
          click: (e) => {
            const { lat, lng } = e.latlng;
            let i = 0;
            // map.invalidateSize();
            map.eachLayer( (layer) => {
                // console.log(layer)
                i > 0 && map.removeLayer(layer);
                i++;
            });

            L.marker([lat, lng], { icon:myIcon }).addTo(map);
            // saveMarkers([lat, lng]);
            // setPosition(e.latlng);
            // console.log('marker set', e)
            childToParent(e.latlng);
          }
        });
        return null;
    };

    const mapStyle = () => {
        const changeClass = (classes == 'hidden') ? setClasses('h-80') : setClasses('hidden');

        const changeButton = (buttonclasses == 'rounded-xl') ? setButonClasses('rounded-t-xl') : setButonClasses('rounded-xl');

        const showText = (showmap == t('show_map') && classes == 'hidden') ? setShowmap(t('hide_map')) : setShowmap(t('show_map'));

        // classes == 'visible' && SetClasses('invisible ');

        // return newHeight;
    };

    const NewMarker = ({lat, lon}) => {
        return ( <Marker icon={myIcon} position={[lat, lon]}
                        eventHandlers={{
                            click: (e) => {
                            // console.log('marker clicked', e)
                            },
                        }}
                >
                </Marker>
        )
    };

    // useEffect(() => {
    //     console.log('height-e', classes);


    // }, [classes]);

    return(
        <React.Fragment>

            <button className="map-background relative overflow-hidden text-center flex flex-1 items-center justify-center text-xs bg-slate-800 p-3 text-white cursor-pointer rounded-xl" 
            onClick={() => {mapStyle()}}>
                {showmap}
            </button>

            <div className={"map-conatainer absolute left-0 right-0 top-full mt-2 z-10 rounded-xl shadow-xl overflow-hidden " + classes}>
                <MapContainer style={{ height: "100%", width: "100%" }} zoom={13} scrollWheelZoom={false} attributionControl={false}>
         
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <NewMarker lat={lat} lon={lon} />
                    <AddMarker />
                    <SetViewOnClick coords={[lat, lon]} />
                </MapContainer>
            </div>
        </React.Fragment>
    )
}

export default NewMap;