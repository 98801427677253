import axios from "axios";

const URL = 'https://nominatim.openstreetmap.org/search';

export const fetchCities = async (query) => {
    const {data} = await axios.get(URL, {
        params: {
            q: query,
            format: "jsonv2",
            email: "mattharrison@gmail.com"
        }
    });
    return data;
}

// const URL = 'http://www.mapquestapi.com/geocoding/v1/address';

// export const fetchCities = async (query) => {
//     const {data} = await axios.get(URL, {
//         params: {
//             location: query,
//             key: "TKHFRWvwcUVG2apMNeO8nFNAZ2oTcnbz",
//         }
//     });
//     return data;
// }