import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import { db } from "./api/db";
import { fetchCities } from "./api/fetchCities";
import FetchWeather from "./api/fetchWeather";
import { weatherTable } from "./api/weatherTable";
import { weatherChart } from "./api/weatherChart";
import { userAuthProvider } from "./api/userAuthProvider";
// import ResetUrl from "./api/resetUrl";
import NewMap from "./api/map";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { Tab, Transition } from '@headlessui/react';
import { useTimeoutFn } from 'react-use';
// import { Alert } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { useDeviceData, isMobile } from 'react-device-detect';
// import { useSearchParams
    // BrowserRouter as Router,
    // Link,
    // useLocation
//   } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

// import { useLiveQuery } from "dexie-react-hooks";

import "./App.css";
// import DoRegister from "./api/doRegister";

import 'moment/locale/ru';

const App = () => {

    const { t } = useTranslation();

    // const [searchParams, setSearchParams] = useSearchParams();

    // i18n.changeLanguage('en');
    // const [enabled, setEnabled] = useState(false);
    const [query, setQuery] = useState('');
    const [coords, setCoords] = useState([51.505, -0.09]);
    const [saveCoords, setSaveCoords] = useState([]);
    const [menu, setMenu] = useState(false);
    const [profile, setProfile] = useState(false);
    const [uId, setUid] = useState(false);
    // const [copySuccess, setCopySuccess] = useState('');
    
    const [savebutton, setSavebutton] = useState(false);
    const [savebuttonText, setSavebuttonText] = useState(t('save_button'));
    const [searchbutton, setSearchbutton] = useState(false);
    const [clearbutton, setClearbutton] = useState('');
    const [locationButton, setLocationButton] = useState(true);
    const [clearHistory, setClearHistory] = useState(false);

    const [weather, setWeather] = useState('');
    const [weatherSix, setWeatherSix] = useState('');
    const [weatherTwelve, setWeatherTwelve] = useState('');
    
    const [selectedIndex, setSelectedIndex] = useState(0);
    // console.log(selectedIndex);

    const [location, setLocation] = useState({});
    // const [loader, setLoader] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isLocationLoading, setLocationLoading] = useState(false);
    const [loader, setLoader] = useState(false);

    const [time, setTime] = useState(0);
    // const [searchHistory, setSearchHistory] = useState([]);
    const [token, setToken] = useState('');
    const [isAuth, setAuth] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [registerResult, setRegisterResult] = useState(false);

    let [isShowing, setIsShowing] = useState(true);
    let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 500);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isMatch, setMatch] = useState(false);
    const [isEnable, setEnable] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const inputField = useRef(null);
    const tabGroup = useRef(null);
    const deviceinfo = JSON.stringify(useDeviceData());

    const storedLang = localStorage.getItem('i18nextLng').slice(0, 2);
    moment.locale(storedLang);

    // const authProvider = userAuthProvider();
    useEffect(() => {
        if (!uId && !localStorage.getItem('uId')) {
            const unique = uuidv4().replaceAll('-', '');
            setUid(unique);
            localStorage.setItem('uId', unique);
        }
        // const getId = localStorage.getItem('uId');
        // console.log("id",uId);

    }, [uId]);

    useEffect(() => {

        // if (!isAuth) {
            localStorage.getItem('auth') ? setAuth(true) : setAuth(false);
        // }


        const theName = JSON.parse(localStorage.getItem('auth'));

        theName && setUsername(theName[1]);

        // console.log("user",username);

        // userAuthProvider.checkAuth()
        //     .then(({ data }) => {
        //         setAuth(data);
        //         console.log("data", data);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         setAuth(false);
        //     });

    }, [username]);

    // const errorLocation = t('no_results');

    const ShowHistory = () => {
        // console.log('history',history.length);

        // const history = useLiveQuery(
        //     () => db.weatherStore.toArray()
        // );

        useEffect(() => {
            
            // console.log(token);

        const getToken = async () => {
            const history = await db.weatherStore.orderBy('id').toArray();

            // console.log('history',history);

            const allHistory = [];

            const result = history.map(index => {
                // console.log('item',item);

                    allHistory.push([
                        index.id, 
                        index.query, 
                        index.lat, 
                        index.lon,
                        index.timestamp
                    ]);

                    // allHistory.id = index.id;
                    // allHistory.query = index.query;
                    // allHistory.timestamp = index.timestamp;
                    // allHistory.latitude = index.latestweatherdata.metcheckData.forecastLocation.latitude;
                    // allHistory.longitude = index.latestweatherdata.metcheckData.forecastLocation.longitude;

                    // console.log('index',index.query);

                setClearHistory(true);
                // console.log('allHistory',allHistory);
                return allHistory;

            });

            // console.log('result',result);
            
            setToken(result);

            // console.log(token);

            // return result;
            // const data = await history;
            
        };

        if (!token) {
            getToken();
        }

        }, []);

        const HistoryComponent = ({item}) => {
            const date = new Date(item[4]);

            // console.log(item[4]);
            // const localLocale = moment();

            return (
                <React.Fragment>
                    <span className="block">{item[1]}</span>
                    <span className="block text-slate-500 py-1">{t('lat')}: {item[2]}, {t('lon')}:{item[3]} 
                     {t('on')} 
                    {date.getDate() + ' ' 
                    + moment(date.getMonth() + 1, 'M').format('MMMM') + ' - ' 
                    + date.getHours() + ':' 
                    + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() 
                    }</span>
                </React.Fragment>
            );
        };

        // console.log('token',token);

        if (token.length === 0) {
            return (<div className="text-center text-white p-4">{t('no_history')}</div>)
        };
        // if (isPromise(token)) {
        return (
            <React.Fragment>
            <ul className="text-xs text-white cursor-pointer">
                {token.length > 0 && (
                    token[0].slice(token[0].length - (token[0].length <= 10 ? token[0].length : 10), token[0].length).reverse().map((item, key) => <li 
                    key={key} 
                    onClick={() => {
                        toggleHistory(item);
                        setMenu(false);
                        setProfile(false);
                    }}
                    className="p-3 pb-2 bg-slate-700 rounded-xl my-2">
                        <HistoryComponent item={item} />
                    </li>)
                )}
            </ul>

            {clearHistory && (
                <button className="rounded-xl bg-pink-500 hover:bg-pink-800 text-white p-3 text-xs my-4 float-right" 
                onClick={() => {
                    setProfile(false);
                    dropTable();
                }}>
                    {t('clear_history')}
                </button>
            )}
            </React.Fragment>
        );
        // }

    };

    function isPromise(value) {
        return Boolean(value && typeof value.then === "function");
    }

    useLayoutEffect(() => {
        const weatherFromDB = db.weatherStore.orderBy('id').last();
        setLoader(true);
        // const searchHistory = db.weatherStore;
        // console.log(db.weatherStore.count());

        const numbers = /^[0-9]+$/;
        const queryParams = new URLSearchParams(window.location.search);
        const queryLat = queryParams.get("lat");
        const queryLon = queryParams.get("lon");
        const queryTab = queryParams.get("tab");

        const isLatitude = (num) => {if (num && isFinite(num) && Math.abs(num) <= 90) return true;}
        const isLongitude = (num) => {if (num && isFinite(num) && Math.abs(num) <= 180) return true;}

        // console.log('isLatitude',isLatitude(queryLat), queryLat);

        if (queryLat && queryLon) {

            if (queryTab && queryTab.length === 1 && queryTab.match(numbers)) { saveTab(queryTab); }

            getWeather(queryLat, queryLon, t('custom_location'));
            setCoords([queryLat, queryLon]);
            setQuery(t('custom_location'));
            setWeather('');
            setWeatherSix('');
            setWeatherTwelve('');
            setTime(0);

            window.history.replaceState({}, document.title, "/");

            // if (queryLat && queryLat.length === 1 && queryLat.match(numbers)) { saveTab(queryLat); }

        } else if (isPromise(weatherFromDB)) {
            weatherFromDB.then(result => {

                setLoader(false);
                // console.log('result', result.latestweatherdata);
                setWeather(weatherTable(result.latestweatherdata, false));
                setWeatherSix(weatherChart(result.latestweatherdata));
                setWeatherTwelve(weatherChart(result.latestweatherdata, 12));

                // const getTheTab = JSON.parse(localStorage.getItem('tabIndex'));
                // getTheTab && setSelectedIndex(getTheTab[1]);

                setQuery(result.query);
                const date = new Date(result.timestamp);

                if ((Date.now() - result.timestamp)/60000 > 60) {
                    setSavebutton(true); 
                    setSavebuttonText(t('save_button_refresh')); 
                }

                    // console.log('date',result.timestamp);
                    // console.log('datenow', (Date.now() - result.timestamp)/60000);
                    
                setTime(date);
                setCoords([
                    result.lat, 
                    result.lon
                ]);
                
                // console.log(result.latestweatherdata);
                // console.log(result);
            })
            .catch(err => {
                console.log(err);
            });


            // const history = db.weatherStore.orderBy('id').toArray();

            // const history = [];

            // db.table("weatherStore").toArray().then(item => { 
            //     // console.log(item);
                
            //     item.map(index => {
            //         history.push([index.id, index.query, index.timestamp, index.latestweatherdata.metcheckData.forecastLocation.latitude, index.latestweatherdata.metcheckData.forecastLocation.longitude])
            //     })
                    
            //         // console.log('item', item[i].timestamp);
                
                
            // }).catch(err => {
            //     console.log(err);
            // });

            // setSearchHistory(history);
            // console.log('history', history);
            // showHistory(db.weatherStore.toArray());
            
            
        }
    }, []);

    const search = async () => {
        // if (e.key === "Enter") {
            // console.log(e.target.defaultValue);
        if (inputField.current.value.length >= 3) {

            // setQuery(e.target.value);

            const data = await fetchCities(query);

            setLocation(data.reverse());
            setSearchbutton(false);
            setLoading(false);
            // setQuery('');

            // console.log(data);
        }
    };

    // const search = (e) => {

    //     const getData = async () => {

    //         const data = await fetchCities(query);
    //         if (data.length > 0 ) setLocation(data.reverse());
    
    //     }

    //     let timer = setTimeout(() => {

    //         if(e.target.defaultValue.length >= 3) {
    //             getData();
    //         }
    
    //     }, 2500);

    //     return () => clearTimeout(timer);


    // };

    const getWeather = async (lat, lon, query) => {
        // console.log(lat, lon);
        
        const weatherData = await FetchWeather(lat, lon, query, localStorage.getItem('uId'), username);
        // console.log(weatherData);
        setWeather(weatherTable(weatherData, true, query, lat, lon, t));
        setWeatherSix(weatherChart(weatherData));
        setWeatherTwelve(weatherChart(weatherData, 12));

        setLoader(false);
        setLoading(false);
        setSavebutton(false);
        setTime(new Date(Date.now()));
        setToken('');
        
        // setSetcoords(weatherData);
        // console.log(weatherData);
    };

    const toggleInput = (item) => {

        setQuery(item.display_name);
        setCoords([item.lat, item.lon]);
        setSavebutton(true);
        setSavebuttonText(t('save_button'));
        // setSavebutton(savebuttonTemplate(lat, lon));
        // console.log(query);
        // setCoords([lat, lon]);
        // console.log(coords);
    };

    const toggleHistory = (item) => {

        setQuery(item[1]);
        setCoords([item[2], item[3]]);
        setSavebuttonText(t('save_button_refresh'));
        setSavebutton(true);
    };

    const childToParent = (childdata) => {
        // console.log('from parent',childdata.lng);
        setQuery(t('custom_location'));
        setCoords([childdata.lat, childdata.lng]);
        setSavebuttonText(t('save_button'));
        setSavebutton(true);
        setSearchbutton(false);
    };

    useEffect(() => {
        // console.log('coords',coords);
        // console.log(query);

        coords.length > 0 && setSaveCoords(coords);

        // console.log(item.lat, item.lon);
        // const newCoords = [item.lat, item.lon];
        // setCoords(newCoords);
    }, [coords, query]);

    useLayoutEffect(() => {

        // weather.length > 0 && setLoading(false);

    }, [weather]);

    const toggleClear = () => {
        setClearbutton(clearbuttonTemplate());
    };

    // useEffect(() => {
    //     inputField.current.value = query;
    // });

    const toggleSave = () => {
        setLocation({});
    };

    const dropTable = () => {
        db.weatherStore
            .orderBy('id')
            .delete()
            .then(deleteCount => {
                console.log( "Deleted " + deleteCount + " objects");
            });

        setQuery('');
        setSaveCoords([]);
        setSavebutton(false);
        setToken('');
        setMenu(false);
        setWeather('');
        setWeatherSix('');
        setWeatherTwelve('');
        setTime(0);
    }

    useEffect(() => {
        if (email && confirmPassword && password === confirmPassword) { setMatch(true) } else { setMatch(false) }
        if (email && password) { setEnable(true) } else { setEnable(false) }
    }, [password, confirmPassword, email]);

    function updateClipboard(newClip) {
        navigator.clipboard.writeText(newClip).then(
          () => {
            // setCopySuccess("Copied!");
            toast.success(t('copied'), {
                theme: "colored"
            })
          },
          () => {
            // setCopySuccess("Copy failed!");
            toast.error(t('copy_failed'), {
                theme: "colored"
            })
          }
        );
    }

    const handleShare = () => {
        const currentUrl = window.location.href;
        const urlForCopy = currentUrl + '?tab=' + selectedIndex + '&lat=' + saveCoords[0] + '&lon=' + saveCoords[1];

        if (isMobile) {
            if (navigator.share) {
            navigator
                .share({
                title: t('weather_pwa'),
                // text: t('check_weather'),
                url: urlForCopy,
                })
                .then(() => {
                console.log('Successfully shared');
                })
                .catch(error => {
                console.error('Something went wrong sharing the blog', error);
                });
            }
        } else {
            navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                updateClipboard(urlForCopy);
                }
            });
        }
    };

    const ShowTime = () => {
        // console.log(time);

        return (
            <div className="text-xs text-gray-700 text-center p-3 pb-1 flex justify-center items-center">
                <span>
                {t('last_update') + ': '
                + moment(time).fromNow()
                }
                </span>

                <button type="button" className="px-2" onClick={handleShare}>
                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 98.333 98.333" fill="#374151">
                    <path d="M81.139,64.48c-4.286,0-8.188,1.607-11.171,4.233l-35.919-18.11c0.04-0.475,0.072-0.951,0.072-1.437
		c0-0.432-0.033-0.856-0.064-1.28l36.024-18.164c2.967,2.566,6.828,4.129,11.058,4.129c9.348,0,16.926-7.579,16.926-16.926
		C98.064,7.578,90.486,0,81.139,0C71.79,0,64.212,7.578,64.212,16.926c0,0.432,0.033,0.856,0.064,1.28L28.251,36.37
		c-2.967-2.566-6.827-4.129-11.057-4.129c-9.348,0-16.926,7.578-16.926,16.926c0,9.349,7.578,16.926,16.926,16.926
		c4.175,0,7.992-1.52,10.944-4.026l36.13,18.217c-0.023,0.373-0.057,0.744-0.057,1.124c0,9.348,7.578,16.926,16.926,16.926
		s16.926-7.579,16.926-16.926S90.486,64.48,81.139,64.48z"></path></svg>
                </button>
            </div>
        )
    }

    const handleLogin = e => {
        e.preventDefault();
        // console.log(email, password);
        // will call authProvider.login({ email, password })
        userAuthProvider.login({ email, password })
        .then(() => {
            toast.success(t('logged_in'), {
                theme: "colored"
            })  
            setShowLogin(false);
            setMenu(false);
            if (!isAuth) {
                setAuth(true);
                const theName = JSON.parse(localStorage.getItem('auth'));
                setUsername(theName[1]);
            }
        })
        .catch((err) =>
            // console.log(err);
            // notify('Invalid email or password')
            toast.error(t('invalid_email'), {
                theme: "colored"
            })
            
        );

        // setAuth(true);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const currentId = localStorage.getItem('uId');
        // console.log("currentId", currentId);

        userAuthProvider.register({ email, password, deviceinfo, currentId},  "register" )
        .then(() => {
            toast.success(t('register_complete'), {
                theme: "colored"
            }) 
            setShowRegister(false);
            setMenu(false);
            if (!isAuth) {
                setAuth(true);
                const theName = JSON.parse(localStorage.getItem('auth'));
                setUsername(theName[1]);
            }
        })
        .catch((err) =>
            toast.error(t('email_exists'), {
                theme: "colored"
            })    
        );

        // const registerData = await DoRegister(email, password, localStorage.getItem('uId'));
        // setRegisterResult(registerData);


        // console.log('registerData',registerData);

        // registerData.then(response => {
        //     console.log('resp',response);

        //     toast.success("Registration complete", {
        //         theme: "colored"
        //     })

        // })

        // .catch(err => {
        //     toast.error("Something wrong, try again", {
        //         theme: "colored"
        //     })
        // })

        // if (registerData) {
        //     toast.success("Registration complete", {
        //         theme: "colored"
        //     })
        // } else {
        //     toast.error("Something wrong, try again", {
        //         theme: "colored"
        //     })
        // }


        // setAuth(true);
    };

    const handleLogOut = e => {
        setProfile(false);
        e.preventDefault();
        if (isAuth) {
            userAuthProvider.logout().catch((err) =>
            toast.error("Something Wrong", {
                theme: "colored"
            }));
            setAuth(false);
            setMenu(false);
            toast.success(t('logged_out'), {
                theme: "colored"
            });
        } else {
            setShowLogin(true);
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

    const saveLoader = () => {
        return (
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        )
    }

    const getUserLocation = () => {

        const showPosition = position => {
            const location = {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude
            }
            console.log('location',location);
            setLocationLoading(false);
            setQuery(t('current_position'));
            setCoords([position.coords.latitude, position.coords.longitude]);
            setSavebutton(true);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log(t('geo_notsupported'));
        }

    }

    const SearchbuttonTemplate = () => {
        return (
            <button 
                type="button"
                onClick={() => {
                    // setSearchbutton(false);
                    setLoading(true);
                    search();
                }}
                disabled={isLoading && true}
                className="save-button text-white items-center inline-flex
                focus:ring-4 focus:ring-blue-300 font-medium rounded-xl 
                text-sm px-5 py-2.5 transition duration-150 ease-in-out
                focus:outline-none bg-gradient-to-r from-sky-400 to-blue-500 absolute right-0">
                    {isLoading && (saveLoader())}
                    {!isLoading && t('search_button')}
            </button>
        )
    }

    const SavebuttonTemplate = () => {
        // console.log(saveCoords[0],saveCoords[1]);
        // !lat && setSavebutton('');
        
        return (
            <button 
                type="button"
                onClick={() => {
                    toggleSave(); 
                    setLoader(true);
                    getWeather(saveCoords[0], saveCoords[1], query); 
                    setLoading(true);
                    setWeather('');
                    setWeatherSix('');
                    setWeatherTwelve('');
                    setTime(0);
                    // setClasses('h-10');
                    // setLoader(saveLoader());
                }}
                disabled={isLoading && true}
                className="save-button width-anime text-white bg-pink-700 hover:bg-pink-800 items-center inline-flex
                focus:ring-4 focus:ring-blue-300 font-medium rounded-xl 
                text-sm px-5 py-2.5  dark:bg-pink-600 dark:hover:bg-pink-700 
                focus:outline-none absolute right-0">
                    {isLoading && (saveLoader())}
                    {!isLoading && savebuttonText}
            </button>
        )
    }

    const GetLocationButton = () => {
        return (
            <span className="absolute inset-y-0 left-10 flex items-center pl-2">
            <button disabled={isLocationLoading && true} type="submit" className="p-1 focus:outline-none focus:shadow-outline" onClick={() => {
                getUserLocation();
                setLocationLoading(true);
                }}>
                    {isLocationLoading && (saveLoader())}
                    {!isLocationLoading && (
            <svg fill="#c9c9c9" stroke="currentColor" viewBox="0 0 297 297" className="w-5 h-5">
                <path d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645
c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645
C259.253,49.703,209.57,0,148.5,0z M148.5,272.689c-22.049-21.366-90.243-93.029-90.243-161.892
c0-49.784,40.483-90.287,90.243-90.287s90.243,40.503,90.243,90.287C238.743,179.659,170.549,251.322,148.5,272.689z"/>
                <path d="M148.5,59.183c-28.273,0-51.274,23.154-51.274,51.614c0,28.461,23.001,51.614,51.274,51.614
c28.273,0,51.274-23.153,51.274-51.614C199.774,82.337,176.773,59.183,148.5,59.183z M148.5,141.901
c-16.964,0-30.765-13.953-30.765-31.104c0-17.15,13.801-31.104,30.765-31.104c16.964,0,30.765,13.953,30.765,31.104
C179.265,127.948,165.464,141.901,148.5,141.901z"/>
            </svg>
                    )}
            </button>
            </span>
        )
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const MobileMenu = () => {
        return (
            <React.Fragment>
                <button type="button" className="inline-flex relative z-30 items-center justify-center p-2 pr-3 pl-0 text-gray-200 hover:text-white focus:outline-none" aria-controls="mobile-menu" aria-expanded="false"
                onClick={() => {
                    setMenu(state => !state);
                    setProfile(false);
                    setShowLogin(false);
                    setShowRegister(false);
                }}>

                    <span className="sr-only">{t('open_mainmenu')}</span>

                    <svg className={(menu ? "hidden" : "block") + " h-8 w-8"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>

                    <svg className={(menu ? "block" : "hidden") + " h-8 w-8"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div className={(menu ? "fixed" : "hidden") + " top-0 bottom-0 left-0 right-0 z-10 bg-slate-800 overflow-y-scroll"}>

                    <div className="max-w-3xl relative mx-auto p-4 pt-24 overflow-hidden">
                    
                    {isAuth && (

                        <ShowHistory />

                    )}

                    {!isAuth && (

                        <div className="p-4 text-sm text-white text-center">{t('please_login')}</div>

                    )}

                    <div className="fixed max-w-3xl mx-auto top-0 py-7 px-4 right-0 left-0 flex place-content-end items-center bg-gradient-to-b from-slate-800 via-slate-800">
                    
                    {isAuth && (
                        <div className="text-white logged-name font-semibold text-xs ml-2 p-2 pr-0 text-ellipsis overflow-hidden"
                        onClick={() => {
                            setProfile(state => !state)
                        }}>
                            {username}
                        </div>
                    )}

                    <div className="ml-3 relative">
                        <div>
                            <button type="button" className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-pink-500" id="user-menu-button" aria-expanded="false" aria-haspopup="true"
                            onClick={() => {
                                setProfile(state => !state)
                            }}>
                            <span className="sr-only">{t('open_usermenu')}</span>
                            <img className={"h-10 w-10 rounded-full " + (isAuth ? "" : "grayscale opacity-50")} src={"./images/user.png"} alt="" />
                            </button>
                        </div>

                        <div className={ (profile ? "block" : "hidden") + " origin-top-right absolute right-0 mt-2 w-36 max-w-xl rounded-xl shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-ellipsis transform opacity-100 scale-100"} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                            {isAuth && (
   
                                    <a href="#!" className="block px-6 py-3 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-0">
                                        {t('profile')}
                                    </a>

                            )}
                            <a href="#!" className="block px-6 py-3 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-1">{t('settings')}</a>

                            {!isAuth && (
                                <a href="#!" className="block px-6 py-3 text-sm text-gray-700" role="menuitem" tabIndex="-1"
                                onClick={() => {
                                    setShowRegister(true);
                                    setProfile(false);
                                }}
                                id="user-menu-item-3">{t('register')}</a>
                            )}

                            <a href="#!" onClick={handleLogOut} className="block px-6 py-3 text-sm text-gray-700" role="menuitem" tabIndex="-1" id="user-menu-item-2">
                               {isAuth ? t('logout') : t('signin')}
                            </a>
                        </div>
                    </div>
                    </div>

                    </div>

                </div>
            </React.Fragment>
        )
    }

    const clearbuttonTemplate = () => {
        // console.log(lat,lon);
        // remove && setClearbutton('');
        return (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button type="submit" className="p-1 focus:outline-none focus:shadow-outline" onClick={() => {setQuery(''); inputField.current.focus();}}>
                <svg fill="#c9c9c9" stroke="currentColor" viewBox="0 0 512 512" className="w-4 h-4"><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
			512,452.922 315.076,256"/></svg>
                </button>
            </span>
        )
    }

    useEffect(() => {
        const getActiveTab = JSON.parse(localStorage.getItem("tabIndex"));
        if (getActiveTab) {
            setSelectedIndex(getActiveTab);
        }
        // console.log(selectedIndex);
    }, []);

    const saveTab = (index) => {
        // localStorage.setItem('tabIndex', index);
        setSelectedIndex(index);
        // console.log('Changed selected tab to:', index);
        // console.log(selectedIndex);
    };

    useEffect(() => {
        localStorage.setItem("tabIndex", selectedIndex);


        // console.log("tab", queryTab);
    }, [selectedIndex]);

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const queryTab = queryParams.get("tab");
    //     saveTab(queryTab);
    //     console.log("tab", queryTab);
    // }, [selectedIndex]);

    const LoaderPlaceholder = () => {
        return (
            <div className="animate-pulse w-full mx-auto py-8">
            <div className="flex space-x-4">
                <div className="flex-1 space-y-2">
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                </div>
            </div>
            </div>
        )
    }

    const LoaderPlaceholderGraph = () => {
        return (
            <div className="animate-pulse w-full mx-auto py-8">
            <div className="flex space-x-4">
                <div className="flex-1 space-y-2">
                    <div className="flex justify-center items-center w-full h-72 bg-slate-800 rounded-xl"> 
                        <svg className="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                        <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path></svg>
                    </div>
                    <div className="h-8 bg-slate-800 rounded-xl"></div>
                    <div className="flex justify-center items-center w-full h-72 bg-slate-800 rounded-xl"> 
                        <svg className="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                        <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path></svg>
                    </div>
                </div>
            </div>
            </div>
        )
    }

    return (

        <div className="main-container container mx-auto p-4 pt-20 max-w-3xl relative">


            <header className="fixed left-0 right-0 top-0 p-4 py-6 z-40 bg-gradient-to-b from-slate-900 via-slate-900 
            max-w-3xl mx-auto">
            
                <div className="flex align-middle justify-center relative items-center">

                <MobileMenu />

                {showLogin && (

                <div className="login-form flex items-center bg-gradient-to-br from-blue-600 to-sky-400 mx-auto p-4 
                fixed top-0 bottom-0 left-0 right-0 z-20 text-gray-300 transition duration-150 ease-in-out">

                <form onSubmit={handleLogin} className="w-full bg-slate-800 p-4 border-2 border-slate-200/20 rounded-xl 
                shadow-xl max-w-lg mx-auto">
                    <div className="form-group mb-6">
                    <label htmlFor="exampleInputEmail2" className="form-label inline-block mb-2 text-sm">{t('email')}</label>
                    <input type="email" required className="form-control
                    bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                    rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full 
                    p-2.5 pr-20 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                    dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                    focus:outline-none focus:ring-2 shadow-lg bg-clip-padding
                        transition
                        ease-in-out
                        " id="exampleInputEmail2"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                    <div className="form-group mb-6 relative">
                    <label htmlFor="exampleInputPassword2" className="form-label inline-block mb-2 text-sm">{t('password')}</label>
                    <input type={passwordShown ? "text" : "password"} required className="
                    bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                    rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full 
                    p-2.5 pr-20 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                    dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                    focus:outline-none focus:ring-2 shadow-lg bg-clip-padding transition ease-in-out"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password" />

                        <div className="absolute inset-y-0 right-0 pr-3 pb-2 flex items-end text-sm leading-5 cursor-pointer" onClick={togglePassword}>
                        <svg className={"w-6 h-6 " + (passwordShown ? "hidden" : "block")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                            </path>
                            </svg>
                            <svg className={"w-6 h-6 "+ (passwordShown ? "block" : "hidden")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21">
                            </path>
                            </svg>
                        </div>

                    </div>
                    <div className="flex justify-between items-center mb-6">
                        <div className="form-group form-check">
                            <input type="checkbox"
                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            id="exampleCheck2" />
                            
                            <label className="form-check-label inline-block text-sm" htmlFor="exampleCheck2">{t('remember_me')}</label>
                        </div>
                        <a href="#!"
                            className="text-blue-600 hover:text-blue-700 focus:text-blue-700 text-sm transition duration-200 ease-in-out">{t('forgot_password')}
                        </a>
                    </div>

                    <button disabled={!isEnable && true} type="submit" className=" w-full px-6 py-4 bg-pink-700 hover:bg-pink-800
                    font-medium text-xs leading-tight text-white rounded-xl shadow-md disabled:opacity-30
                    transition duration-150 ease-in-out"
                    onClick={() => {
                        console.log("is", isAuth);
                    }}>
                        {t('login')}</button>
                    <p className="mt-6 text-center text-sm">{t('not_amember')} <a href="#!"
                        className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out"
                        onClick={() => {
                            setShowRegister(true);
                            setShowLogin(false)
                        }}>{t('register')}</a>
                    </p>
                </form>
   
                </div>

                )}

                {showRegister && (

                    <div className="login-form flex items-center bg-gradient-to-br from-blue-600 to-sky-400 mx-auto p-4 
                    fixed top-0 bottom-0 left-0 right-0 z-20 text-gray-300 transition duration-150 ease-in-out">

                    <form onSubmit={handleRegister} className="w-full bg-slate-800 p-4 border-2 border-slate-200/20 
                    rounded-xl shadow-xl max-w-lg mx-auto">

                        <div className="form-group mb-6">
                        <label htmlFor="exampleInputEmail2" className="form-label inline-block mb-2 text-sm">{t('email')}</label>
                        <input type="email" required className="form-control
                        bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                        rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full 
                        p-2.5 pr-20 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                        focus:outline-none focus:ring-2 shadow-lg bg-clip-padding
                            transition
                            ease-in-out
                            " id="exampleInputEmail2"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            aria-describedby="emailHelp" placeholder="Enter email" />
                        </div>

                        <div className="form-group mb-6 relative">
                        <label htmlFor="exampleInputPassword2" className="form-label inline-block mb-2 text-sm">{t('password')}</label>
                        <input type={passwordShown ? "text" : "password"} required className="
                        bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                        rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full 
                        p-2.5 pr-20 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                        focus:outline-none focus:ring-2 shadow-lg bg-clip-padding transition ease-in-out"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Password" />

                            <div className="absolute inset-y-0 right-0 pr-3 pb-2 flex items-end text-sm leading-5 cursor-pointer" onClick={togglePassword}>
                            <svg className={"w-6 h-6 " + (passwordShown ? "hidden" : "block")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                            </path>
                            </svg>
                            <svg className={"w-6 h-6 "+ (passwordShown ? "block" : "hidden")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21">
                            </path>
                            </svg>
                            </div>

                        </div>

                        <div className="form-group mb-6 relative">
                        <label htmlFor="exampleInputPassword3" className="form-label inline-block mb-2 text-sm">{t('repeat_password')}</label>
                        <input type={passwordShown ? "text" : "password"} required className="
                        bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                        rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full 
                        p-2.5 pr-20 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                        focus:outline-none focus:ring-2 shadow-lg bg-clip-padding transition ease-in-out"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder="Password" />

                            <div className="absolute inset-y-0 right-0 pr-3 pb-2 flex items-end text-sm leading-5 cursor-pointer" onClick={togglePassword}>
                            <svg className={"w-6 h-6 " + (passwordShown ? "hidden" : "block")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                            </path>
                            </svg>
                            <svg className={"w-6 h-6 "+ (passwordShown ? "block" : "hidden")} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21">
                            </path>
                            </svg>
                            </div>

                        </div>

                        <div className="flex justify-between items-center mb-6">
                        <div className="form-group form-check">
                            <input type="checkbox" required
                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            id="exampleCheck3" />
                            
                            <label className="form-check-label inline-block text-sm" htmlFor="exampleCheck3">{t('agree_terms')}</label>
                        </div>
                        </div>

                        <button disabled={!isMatch && true} type="submit" className=" w-full px-6 py-4 mt-5 bg-pink-700 hover:bg-pink-800
                        font-medium text-xs leading-tight text-white rounded-xl shadow-md disabled:opacity-30
                        transition duration-150 ease-in-out"
                        onClick={() => {
                            console.log("is", isAuth);
                        }}>
                            {t('registration')}</button>
                        <p className="mt-6 text-center text-sm">{t('already_registered')} <a href="#!"
                        className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out"
                        onClick={() => {
                            setShowRegister(false);
                            setShowLogin(true)
                        }}>{t('login')}</a>
                    </p>

                    </form>

                    </div>

                )}

                <input
                    type="text"
                    ref={inputField}
                    className="search bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                    rounded-xl focus:ring-pink-500 focus:border-pink-500 block w-full
                    pl-9
                    p-2.5 pr-10 overflow-ellipsis dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                    dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500 
                    focus:outline-none focus:ring-2 shadow-lg"
                    placeholder={t('search_placeholder')}
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value); 
                        setSearchbutton(true);
                        setSavebutton(false);
                    }}
                    onFocus={() => {toggleClear()}}
                    // onBlur={() => {setClearbutton('')}}

                    // onKeyUp={search}
                />
                
                {locationButton && (
                    <GetLocationButton />
                )}

                {clearbutton}

                {searchbutton && (
                    <SearchbuttonTemplate />
                )}

                {savebutton && (
                    <SavebuttonTemplate />
                )}
                
                </div>

            </header>

            <div id="search-results" className="relative pb-2">

                {location.length === 0 && (
                    // toast.error(t('no_results'), {
                    //     theme: "colored"
                    // })
                    <div className="text-center text-white p-2">{t('no_results')}</div>
                )}

                {location.length > 0 && ( location.slice(0, 10).reverse().map((item, index) => (
                    <div 
                        className="highlight py-2 px-4 my-2 bg-white rounded-lg shadow-md 
                        hover:bg-gray-100 dark:bg-gradient-to-r from-sky-500 to-teal-400 
                        hover:from-pink-500 hover:to-yellow-500 dark:border-gray-700 dark:hover:bg-gray-700 cursor-pointer" 
                        key={index}
                        onClick={() => {
                            toggleInput(item);
                        }}
                    >
                        <h3 className="mb-1 text-sm font-bold text-gray-900 dark:text-white">{item.display_name}</h3>
                        <p className="font-normal text-xs text-gray-700 dark:text-white">{t('lat')}: {item.lat} {t('lon')}: {item.lon}</p>
                    </div>
                )))}

            </div>

            <div className="w-full">
                <Tab.Group defaultIndex={1} selectedIndex={selectedIndex} onChange={
                    (index) => {
                        saveTab(index);
                    }
                }>

                <div className="flex flex-row justify-center relative">

                    <Tab.List className="flex grow space-x-1 rounded-xl bg-gray-700/20 p-1 mr-2">
                        <Tab
                        className={({ selected }) =>
                        classNames(
                        'w-full rounded-lg py-2.5 text-xs leading-5 text-white',
                        'focus:outline-none',
                        selected
                            ? 'bg-gray-700 shadow'
                            : 'text-blue-100 hover:bg-gray-800'
                        )}>24 {t('hours')}</Tab>
                        <Tab className={({ selected }) =>
                        classNames(
                        'w-full rounded-lg py-2.5 text-xs leading-5 text-white',
                        'focus:outline-none',
                        selected
                            ? 'bg-gray-700 shadow'
                            : 'text-blue-100 hover:bg-gray-800'
                        )}>12 {t('hours')}</Tab>
                        <Tab className={({ selected }) =>
                        classNames(
                        'w-full rounded-lg py-2.5 text-xs leading-5 text-white',
                        'focus:outline-none',
                        selected
                            ? 'bg-gray-700 shadow'
                            : 'text-blue-100 hover:bg-gray-800'
                        )}>6 {t('hours')}</Tab>
                    </Tab.List>

                    <NewMap lat={coords[0]} lon={coords[1]} childToParent={childToParent} />

                </div>

                {time !== 0 && (
                    <ShowTime />
                )}

                <Tab.Panels className="mt-2">
                    <Tab.Panel>
                        <div id="weather-results" className="relative rounded-xl overflow-hidden">
                            {weather}
                        </div>

                        {loader && (
                            <LoaderPlaceholder />
                        )}

                    </Tab.Panel>
                    <Tab.Panel>
                        <div id="weather-results" className="relative overflow-hidden">
                            {weatherTwelve}
                        </div>

                        {loader && (
                            <LoaderPlaceholderGraph />
                        )}

                    </Tab.Panel>
                    <Tab.Panel>
                        <div id="weather-results" className="relative overflow-hidden">
                            {weatherSix}
                        </div>

                        {loader && (
                            <LoaderPlaceholderGraph />
                        )}

                    </Tab.Panel>
                </Tab.Panels>
                </Tab.Group>
            </div>

            <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false} />

        </div>

    );
}

export default App;