import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            search_placeholder: 'Type city or address...',
            save_button: 'Save',
            save_button_refresh: 'Refresh',
            not_good_to_fly: 'Not good to fly',
            fly_with_caution: 'Fly with caution',
            good_to_fly: 'Good to fly',
            time: 'Time',
            temperature: 'Temperature',
            clouds: 'Clouds',
            chance_of_rain: 'Chance of rain',
            rain: 'Rain',
            snow: 'Snow',
            chance_of_snow: 'Chance of snow',
            wind_speed: 'Wind speed',
            wind_direction: 'Wind direction',
            humidity: 'Humidity',
            is_good_to_fly: 'Good to fly?',
            km_h: 'km/h',
            mm_h: 'mm/h',
            something_wrong: 'Weather server is offline, try again later!',
            last_update: 'Last Update',
            show_map: 'Show Map',
            hide_map: 'Hide Map',
            custom_location: 'Custom Location',
            no_results : 'No results, try different city or use a map',
            clear_history: 'Clear history',
            no_history: 'No search history',
            search_button: 'Search',
            open_mainmenu: 'Open main menu',
            open_usermenu: 'Open user menu',
            profile: 'Profile',
            settings: 'Settings',
            register: 'Register',
            logout: 'Log Out',
            signin: 'Sign In',
            login: 'Log In',
            email: 'Email address',
            password: 'Password',
            remember_me: 'Remember me',
            forgot_password: 'Forgot password?',
            not_amember: 'Not a member?',
            repeat_password: 'Repeat Password',
            agree_terms: 'Agree with terms of use',
            registration: 'Registration',
            already_registered: 'Already registered?',
            lat: 'lat',
            lon: 'lon',
            current_position: 'Current Position',
            geo_notsupported: 'Geo Location not supported by browser',
            logged_out: 'Logged Out',
            logged_it: 'Logged In',
            invalid_email: 'Invalid email or password',
            register_complete: 'Registration complete',
            email_exists: 'Email alerady registered',
            please_login: 'Please Login or Register to see search history',
            hours: 'hours',
            on: ' on ',
            weather_pwa: "Weather PWA",
            check_weather: "Check out weather",
            copied: "Copied",
            copy_failed: "Copy failed!",

        }
      },
      ru: {
        translation: {
            search_placeholder: 'Введите город или адрес...',
            save_button: 'Сохранить',
            save_button_refresh: 'Обновить',
            not_good_to_fly: 'Не рекомендуется',
            fly_with_caution: 'С осторожностью',
            good_to_fly: 'Отличная погода',
            time: 'Время',
            temperature: 'Температура',
            clouds: 'Облачность',
            chance_of_rain: 'Вероятность дождя',
            rain: 'Дождь',
            snow: 'Снег',
            chance_of_snow: 'Вероятность снега',
            wind_speed: 'Ветер',
            wind_direction: 'Направление',
            humidity: 'Влажность',
            is_good_to_fly: 'Можно летать',
            km_h: 'км/ч',
            mm_h: 'мм/ч',
            something_wrong: 'Что-то пошло не так, попробуйте еще раз!',
            last_update: 'Обновлено',
            show_map: 'Показать карту',
            hide_map: 'Скрыть карту',
            custom_location: 'Точка на карте',
            no_results : 'Нет результата, введите другой город или воспользуйтесь картой',
            clear_history: 'Очистить историю',
            no_history: 'Нет истории поиска',
            search_button: 'Поиск',
            open_mainmenu: 'Открыть главное меню',
            open_usermenu: 'Открыть меню пользователя',
            profile: 'Профиль',
            settings: 'Настройки',
            register: 'Регистрация',
            logout: 'Выйти',
            signin: 'Войти',
            login: 'Войти',
            email: 'Почта',
            password: 'Пароль',
            remember_me: 'Запомнить',
            forgot_password: 'Забыли пароль?',
            not_amember: 'Не зарегистрированы?',
            repeat_password: 'Повторите пароль',
            agree_terms: 'Соглашаюсь с условиями использования',
            registration: 'Регистрация',
            already_registered: 'Уже зарегистрированы?',
            lat: 'Ш',
            lon: 'Д',
            current_position: 'Текущее местоположение',
            geo_notsupported: 'Сервис геолокации не поддерживается',
            logged_out: 'Вы вышли',
            logged_it: 'Вы успешно зашли',
            invalid_email: 'Неправильный пароль или email',
            register_complete: 'Успешная регистрация',
            email_exists: 'Email уже используется',
            please_login: 'Пожалуйста войдите или зарегистрируйтесь, чтобы увидеть историю поиска',
            hours: 'час',
            on: ' - ',
            weather_pwa: "Погода для дронов",
            check_weather: "Посмотреть погоду",
            copied: "Ссылка скопирована",
            copy_failed: "Ошибка копирования",

        }
      }
    }
  });

export default i18n;