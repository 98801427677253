import axios from "axios";
import { useDeviceData } from 'react-device-detect';

const URL = 'https://weather-pwa.ru/api/';

const FetchWeather = async (lat, lon, query, uId, username) => {
  
    // console.log(lat, lon);
    const deviceData = JSON.stringify(useDeviceData());
    console.log(uId);

    const {data} = await axios.get(URL, {

        headers: { 
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept',
            "Content-Type": "application/json",
          },
        timeout: 49000,
        params: {
            latitude: lat,
            longitude: lon,
            deviceinfo: deviceData,
            query: query,
            uid: uId,
            username: username
        }
    })
    .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

    // console.log(data);

    return data;
};

export default FetchWeather;