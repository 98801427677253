import React, {useState} from "react";
// import { animated, useSpring } from '@react-spring/web'
import { db } from "./db";
import moment from "moment";
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';

async function addWeatherDB(data, query, lat, lon) {
    try {
      const id = await db.weatherStore.put({
        "latestweatherdata": data,
        "timestamp": Date.now(),
        "query" : query,
        "lat": lat,
        "lon": lon
      });
      console.log("Saved");

    } catch (error) {
      console.log(error);
    }
}

const temperatureBg = (temp) => {
    const styles  = {
        background: `linear-gradient(90deg, 
            hsla(${30 + 240 * (30 - temp) / 60}, 70%, 50%, 0.25) 0%, 
            hsla(${30 + 240 * (30 - temp) / 60}, 70%, 50%, ${temp/100}) 100%)`,
        // color: contrastColor(temp)
    };
    return styles;
};

const totalcloudBg = (cloud) => {
    const styles  = {
        background: `linear-gradient(90deg, 
            rgba(134, 168, 231,.4) 0%,
            rgba(107, 143, 209,${cloud/100}) 100%)`,
    };
    return styles;
};

const chanceofrainBg = (rain) => {

    let halfCell = (rain < 50) ? 50 : rain;
    halfCell = (rain < 10) ? 0 : halfCell;
    const styles  = {
        background: `linear-gradient(90deg, 
            rgba(134, 168, 231,.4) 0%, 
            rgba(160, 107, 209,${rain/100}) ${halfCell}%)`,
    };
    return styles;
};

const windspeedBg = (wind) => {
    const styles  = {
        background: `linear-gradient(90deg, 
            hsla(${3.5 * (40 - wind*1.609)}, 50%, 50%, 0.15) 0%, 
            hsla(${3.5 * (40 - wind*1.609)}, 50%, 50%, ${0.1 + wind*1.609/100}) 100%)`,
    };
    return styles;
};

const windDirection = (direction) => {
    const styles  = {
        transform: `rotateZ(${direction}deg)`
    };
    return styles;
};

export const WeatherInner = ({data}) => {
        
    const [show, setShow] = useState({});
    const [showClear, setshowClear] = useState(false);
    const { t } = useTranslation();

    // i18n.changeLanguage('ru');

    const isItGood = (temp, rain, wind, returnText) => {
        let result = "drone-icon notgood-to-fly";
        let text = t('not_good_to_fly');
        const theTemp = parseInt(temp, 10); 
        const theWind = Math.round(parseInt(wind, 10)*1.609); 
        const theRain = parseInt(rain, 10); 
        if ( theTemp >= -10 && theTemp < 35) {
          result = (theWind <= 30 && theRain < 51) ? "drone-icon fly-with-caution" : result;
          text = (theWind <= 30 && theRain < 51) ? t('fly_with_caution') : text;
          if (theTemp >= 4 && theTemp < 28 && theRain < 16) {
            result = (theWind <= 25) ? "drone-icon good-to-fly" : result;
            text = (theWind <= 25) ? t('good_to_fly') : text;
          }
        }
        return (returnText) ? text : result;
    };

    const weatherHeader = () => {
        return (
            <div className="weather weather-header flex justify-between items-stretch text-slate-600 bg-slate-800 text-xs capitalize text-center py-4">
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center" >

                    <div><SVG
                        src={"./images/time.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>

                    <div className="hidden md:block pt-1">{t('time')}</div>
    
                 
                </div>
    
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/temperature.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('temperature')}</div>
                </div>
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/cloud.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('clouds')}</div>
                </div>
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/rain.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('chance_of_rain')}</div>
                    
                </div>
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/wind.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('wind_speed')}</div>
                    
                </div>
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/compass.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('wind_direction')}</div>
                </div>
                <div className="weather-cell flex-1 truncate flex flex-col items-center justify-center">
                    <div><SVG
                        src={"./images/drone.svg"} width="20" height="20" 
                        className="time-icon"
                    />
                    </div>
                    <div className="hidden md:block pt-1">{t('is_good_to_fly')}</div>
                    
                </div>
            </div>
        )
    }

    const handleClick = (index) => {
        setShow({});
        setShow(state => ({
            ...state, // <-- copy previous state
            [index]: !state[index] // <-- update value by index key
        }));

        // console.log(index);
        // for (let step=0, step < 20, step++) {
        //     show[step] && setshowClear(true); break;
        // }

        // setshowClear(true);

        // setShow(currentShow => !currentShow);
        // console.log(e.currentTarget.id);
        
    }

    const hideAll = (index) => {
        setShow({});
        setshowClear(false);
    }

    return (

        <div id="weather-list">
        
        {data.slice(0, 24).map((e, index) => (

        <React.Fragment key={index}>

        {index === 0 && (weatherHeader())}
        

        <div className={"weather-item cursor-pointer flex justify-between relative text-white  text-sm items-stretch text-center border-t first-of-type:border-0 border-slate-900 " + (show[index] ? "bg-gray-800" : "bg-gray-700")}

        onClick={() => {handleClick(index)}} 
        >

            <div className="weather-cell flex-1 py-1 flex relative items-center content-center text-center justify-center">
                <div className={"flex items-baseline px-1 py-1 rounded-xl " + (show[index] ? " bg-gradient-to-br from-pink-500 to-purple-600" : "text-white bg-none")}>
                    {moment(moment(e.utcTime).valueOf() + moment.parseZone().utcOffset()*60000).hour()}
                    <span className="text-xs text-supersmall">:00</span>
                </div>
            </div>

            <div className={"weather-cell flex-1 py-3 flex relative items-center content-center text-center justify-center " + (show[index] ? "invisible" : "visible") }
                style={temperatureBg(e.temperature)}>
                <div className="flex items-baseline">
                    {e.temperature}
                     <sup>&#176;</sup>
                </div>
             </div>

                        <div className={"weather-cell flex-1 py-3 flex relative items-center content-center text-center justify-center overflow-hidden " + (show[index] ? "invisible" : "visible")}
                        style={totalcloudBg(e.totalcloud)}>
                        <div className="flex items-baseline">
                            {e.totalcloud}
                            <span className="text-xs text-supersmall">%</span>
                        </div>
                        </div>

                        <div className={"weather-cell flex-1 py-3 flex relative items-center content-center text-center justify-center overflow-hidden " + (show[index] ? "invisible" : "visible")}
                        style={chanceofrainBg(e.chanceofrain)}>
                        <div className="flex items-baseline">
                            {e.chanceofrain}
                            <span className="text-xs text-supersmall">%</span>
                        </div>
                        </div>

                        <div className={"weather-cell flex-1 py-3 flex relative items-center content-center text-center justify-center " + (show[index] ? "invisible" : "visible")}
                        style={windspeedBg(e.windspeed)}>
                        <div className="flex items-baseline">
                            {Math.floor(e.windspeed*1.609)}
                            <span className="text-xs text-supersmall">{t('km_h')}</span>
                        </div>
                        </div>

                        <div className={"weather-cell text-xs flex-1 py-3 flex relative items-center content-center text-center justify-center overflow-hidden " + (show[index] ? "invisible" : "visible")}>
                        <div className="flex items-baseline">
                            <em className="wind-arrow" style={windDirection(e.winddirection)}>
                            <SVG
                                src={"./images/arrow.svg"} width="10" height="10"
                                fill="#fff"
                                className="arrow-icon" 
                            />
                            </em> 
                            <em className="pl-1">{e.winddirection}<sup>&#176;</sup></em>
                        </div>
                        </div>

                        <div className="weather-cell flex-1 py-3 flex relative items-center content-center text-center justify-center">
                            <SVG
                                src={"./images/drone.svg"} width="24" height="24" 
                                className={isItGood(e.temperature, e.chanceofrain, e.windspeed*1.609)} 
                            />

                            <div className={"absolute right-1/2 w-auto whitespace-nowrap top-4 opacity-50 text-xs mr-5 " + (show[index] ? "" : "hidden")}>
                                {isItGood(e.temperature, e.chanceofrain, e.windspeed*1.609, true)} 
                            </div>
                        </div>

                </div>

        {show[index] && (

            <div className=" bg-gray-700 bg-gradient-to-b from-gray-800 to-slate-800 text-white flex flex-row justify-center">

            <div className="weather-row-hidden flex flex-col relative">

                <div className={ e.icon + "-icon flex relative items-center content-center text-center justify-center -mt-2 -mr-1"}><SVG
                    src={"./images/weather/" + e.icon + ".svg"} width="95" height="95" 
                    className="time-icon"
                />
                </div>

                <div className="flex items-center content-center text-center justify-center font-normal text-3xl flex-col pb-3 -mt-2">
                    <div>{e.temperature}<sup className="font-light">&#176;</sup></div>
                     <div className="text-xs text-supersmall">{t('temperature')}</div>
                </div>

            </div>

            <div className="weather-row-hidden pb-2 pr-2 flex justify-start text-center flex-wrap flex-row">

                <div className="flex items-center content-center grow text-center justify-center self-center font-normal text-3xl flex-col m-1 p-3 py-1 rounded-xl bg-slate-600">
                    <div>{e.humidity}<sub className="text-xs text-supersmall">%</sub></div>
                     <div className="text-xs text-supersmall">{t('humidity')}</div>
                </div>

                <div className="flex items-center content-center grow text-center justify-center self-center font-normal text-3xl flex-col m-1 p-3 py-1 rounded-xl bg-slate-600" style={totalcloudBg(e.totalcloud)}>
                    <div>{e.totalcloud}<sub className="text-xs text-supersmall">%</sub></div>
                     <div className="text-xs text-supersmall">{t('clouds')}</div>
                </div>

                <div className="flex items-center content-center text-center justify-center self-center font-normal text-3xl flex-col m-1 p-3 py-1 rounded-xl bg-slate-600" style={chanceofrainBg(e.chanceofrain)}>
                    <div>{e.chanceofrain}<sub className="text-xs text-supersmall">%</sub></div>
                     <div className="text-xs text-supersmall">{t('rain')}</div>
                </div>

                <div className="flex items-center content-center text-center justify-center self-center font-normal text-3xl flex-col m-1 p-3 py-1 rounded-xl bg-slate-600">
                    <div>{e.chanceofsnow}<sub className="text-xs text-supersmall">%</sub></div>
                     <div className="text-xs text-supersmall">{t('snow')}</div>
                </div>

                <div className="flex items-center grow content-center text-center justify-center self-center font-normal text-3xl flex-col m-1 p-2 py-1 rounded-xl bg-slate-600 relative">
                    <div className="flex items-center">
                    <em className="wind-arrow m-1" style={windDirection(e.winddirection)}>
                            <SVG
                                src={"./images/arrow.svg"} width="10" height="10"
                                fill="#fff"
                                className="arrow-icon" 
                            />
                            </em>
                            {e.winddirection}<sup className="text-xs">&#176;</sup></div>
                     <div className="text-xs text-supersmall">{t('wind_direction')}</div>
                </div>

                <div className="flex items-center content-center grow text-center justify-center self-center font-normal text-3xl flex-col m-1 p-2 py-1 rounded-xl bg-slate-600" style={windspeedBg(e.windspeed)}>
                    <div className="flex items-baseline">
                         {Math.floor(e.windspeed*1.609)}<sub className="text-xs text-supersmall">{t('km_h')}</sub></div>
                     <div className="text-xs text-supersmall">{t('wind_speed')}</div>
                </div>

            </div>

            </div>

         )}

        </React.Fragment>

        ))}
        
        {showClear && (

            <div className="bg-pink-500 p-5 rounded-full fixed right-5 bottom-5 clear-button"
            onClick={hideAll}>
                <svg fill="#fff" stroke="currentColor" viewBox="0 0 512 512" className="w-4 h-4"><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
                512,452.922 315.076,256"/></svg>
            </div>

        )}

    </div>

    )
}

export const weatherTable = (data, useUpdate = false, query, lat, lon, t) => {

    // console.log(typeof data);

    if (typeof data === "object") {

        useUpdate && (addWeatherDB(data, query, lat, lon));

        // console.log(Date.now());

        return (
            <WeatherInner data={data.metcheckData.forecastLocation.forecast} />
        );
        
    } else {
        return (
            <div id="weather-list" className="text-white text-center p-4">
                {t('something_wrong')}
            </div>
        );
    }

}